span.ciculo {
  border-radius: 100%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.31);
  border: 3px solid rgba(0, 0, 0, 0.31);
  color: #eaedef;
  text-align: center;
  font-size: 20px;
}
.ciculoAzul {
  border-radius: 100%;
  width: 34px;
  height: 34px;
  padding: 10px;
  background: #0070FF;
  border: 3px solid #0070FF;
  color: #eaedef;
  text-align: center;
  font-size: 20px;
}