.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Arrow & Hover Animation */

.arrow{
  position: absolute;
  top: 60%;
  left: 80%;
}
.arrow span{
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid #FFFFFF;
  border-right: 5px solid #FFFFFF;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}
.arrow span:nth-child(2){
  animation-delay: -0.2s;
}
.arrow span:nth-child(3){
  animation-delay: -0.4s;
}
@keyframes animate {
  0%{
    opacity: 0;
    transform: rotate(45deg) translate(-20px,-20px);
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    transform: rotate(45deg) translate(20px,20px);
  }
}

.nav-link.active{
  /*text-decoration-color: #007bff;*/
  /*text-underline-position: under;*/
  border-bottom: 5px solid #007bff;
}